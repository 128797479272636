export const SET_APP_NAME = "GENERAL_SET_APP_NAME";
export const SET_AUTH_STATUS = "AUTH_SET_STATUS";
export const SET_USER_INFO = "AUTH_SET_USER_INFO";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_CHANGED = "GET_USER_CHANGED";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const LOADING = "LOADING";

export const DEFAULTRESPONSE = {
  noResponseFromApi: "Error al realizar la petición.",
};
