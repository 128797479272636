import { createMuiTheme } from "@material-ui/core/styles";
import { themeColors } from "./themeColors";
//import MuliMedium from "./assets/fonts/Muli-Medium.ttf";

export const muiTheme = (appName) =>
  createMuiTheme({
    palette: {
      primary: {
        main: `${themeColors[appName]["primaryColor"]}`, // oscuro
        hover: `${themeColors[appName]["onhoverPrimaryColor"]}`,
      },
      secondary: {
        main: `${themeColors[appName]["secondaryColor"]}`, //claro
      },
      onhoverPrimaryColor: `${themeColors[appName]["onhoverPrimaryColor"]}`, //claro opaco
    },
    logo: {
      backgroundImage: `url('${appName}Logo.png')`,
    },
    pageBackground: {
      backgroundImage: `url('${appName}Background.png')`,
    },
    typography: {
      fontFamily: ["Muli", "Arial", "sans-serif"].join(","),
    },
  });
