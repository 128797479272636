import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { SET_CURRENT_USER, GET_ERRORS, DEFAULTRESPONSE } from "./types";

export /**
 * AuthAction getMenu
 *
 * @method GET
 * @param {*}
 */
const getMenu = (userid) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      userid: userid,
    });
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/menu",
      myConfig
    );
    dispatch({
      type: SET_CURRENT_USER,
      payload: response.data.result.records,
    });
  } catch (error) {
    const { response } = error;
    console.log(response);
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};
