import React, { Component } from "react";
import { connect } from "react-redux";

import ShowAlert from "../components/alert.component";

/**
 * Login Component ( full view Login component, render a login
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class Alert
 * @extends {Component}
 * @returns Redux connect
 */

export class Alert extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(pagename) {
    if (this.props.history)
      if (
        this.props.location.state.pagename &&
        this.props.location.state.pagename !== ""
      ) {
        this.props.history.push({
          pathname: this.props.location.state.pagename,
        });
      }
  }
  render() {
    return (
      <ShowAlert
        value={this.props.location.state.message}
        onClick={this.handleClick}
      ></ShowAlert>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Alert);
