import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import {
  getApplications,
  getUserById,
  createUser,
} from "../../actions/user.action";
import { makeStyles } from "@material-ui/core/styles";
import UserRoles from "../users/userRoles.view";
import UserCustomer from "../users/userCustomer.view";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

/**
 * UserForm Component ( full view for form to create/modify user )
 *
 * @export Class Component
 * @class UserForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "0px 15px",
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
  },
  buttonSave: {
    margin: "3px 15px",
    flex: 1,
    float: "right",
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: { background: "#6D6E71 0% 0% no-repeat padding-box" },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.primary.main,
  },
}));

const UserForm = (props) => {
  const classes = useStyles();

  const {
    userId,
    setCardState,
    setShowUserAlert,
    setUserChanged,
    getApplications,
    getApplicationsResponse,
    getUserById,
    getUserResponse,
    getUserChangedResponse,
    setUserErrorResponse,
    createUser,
    history,
  } = props;

  const [tab, setTab] = useState(0);
  const [selectedAppId, setSelectedAppId] = useState(0);
  const [errors, setErrors] = useState("");
  const [errorsCustomer, setErrorsCustomer] = useState(true);
  const [formData, setFormData] = useState({
    id: "",
    names: "",
    surnames: "",
    email: "",
    document: "",
    username: "",
    customer: [],
  });
  const [rolesData, setRolesData] = useState([]);

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
  const [, forceUpdate] = useState();

  const handleChangeTab = (event, newTab) => {
    if (!allValid()) {
      return;
    }
    if (newTab === 2 && !validateCustomerPrincipal()) {
      setErrorsCustomer(true);
      return;
    }
    setTab(newTab);
  };

  const handleUserRoles = (id) => {
    /*setLoading({
      Loading: false,
      SelectId: 0
    });*/
    setSelectedAppId(id);
  };

  const syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const allValid = () => {
    if (!validator.current.allValid()) {
      console.log("NOT Valid");
      validator.current.showMessages();
      forceUpdate(1);
      return false;
    } else {
      return true;
    }
  };

  const saveUser = (e) => {
    e.preventDefault();
    //console.log("saveUser", formData, rolesData);
    if (allValid()) {
      //Organizar los roles en la estructura del endpoint

      //Se buscan solo los atributos que corresponden a Arrays
      const key = Object.keys(rolesData).filter(
        (key) => Array.isArray(rolesData[key]) === true
      );
      let saveRoles = [];
      let countAssignedRoles = 0;

      key.forEach((appId) => {
        let rolesApp = rolesData[appId];

        //Se obtienen los assignados para contarlos
        countAssignedRoles += rolesApp.filter((r) => r.assigned === true)
          .length;

        //Se organiza de acuerdo a la estructura
        rolesApp.forEach((role) => {
          saveRoles.push({
            role: role.name,
            assigned: role.assigned,
          });
        });
      });
      //console.log("countAssignedRoles", countAssignedRoles, saveRoles);

      if (countAssignedRoles === 0) {
        setErrors("*Se requiere mínimo un rol");
        return;
      } else {
        setErrors(""); //TO-DO temporal mientras se revisa en el editar como cargar el state con todos los assigned en true
      }

      //Organizar los roles para incluirlos dentro de un atributo roles y eliminarlos de ser array
      let userData = Object.assign(formData, { roles: saveRoles });

      //Tansformar los datos de las empresas a como las espera el servicio
      var customers = formData.customer;
      customers.forEach((customer) => {
        if (customer.principal) {
          customer.assigned = true;
        }
      });
      delete formData.customers;
      userData = Object.assign(formData, { customer: customers });

      createUser(!!userId ? "modify" : "create", userData, history);
    }
  };

  const backButton = () => {
    setCardState(true);
    setTab(0);
  };

  const validateCustomerPrincipal = () => {
    return formData.customer.some((customer) => customer.principal === true);
  };

  useEffect(() => {
    console.log("setUserChanged useeffect", userId);
    setUserChanged(userId);
  }, [setUserChanged]);

  useEffect(() => {
    console.log("getUserById useeffect", userId);
    getUserById(userId);
  }, [userId]); //getUserById se elimina para que recarge cada vez que cambia el userID

  useEffect(() => {
    console.log("getUserResponse useeffect", getUserResponse);
    if (!!getUserResponse) {
      setFormData((prevState) => ({
        id: getUserResponse.id,
        names: getUserResponse.names,
        surnames: getUserResponse.surnames,
        email: getUserResponse.email,
        document: getUserResponse.document,
        username: getUserResponse.userName,
        customer: [],
      }));

      setErrors("");
      setErrorsCustomer(false);
    }
  }, [getUserResponse]);

  useEffect(() => {
    if (!!setUserErrorResponse) {
      console.log(
        "setErrorResponse useeffect",
        setUserErrorResponse.result.information
      );
      //Transformar los datos a como los espera la vista TODO
      /*var customers = response.data.result.customers;
      customers.forEach((customer) => {
        if (customer.principal === true) {
          customer.assigned = false;
        }
      });*/
      setErrors(setUserErrorResponse.result.information);
    }
  }, [setUserErrorResponse]);

  useEffect(() => {
    console.log("getUserChangedResponse useeffect", getUserChangedResponse);
    if (!!getUserChangedResponse) {
      setCardState(true);
      setShowUserAlert(true);
      setTab(0);
      setUserChanged(getUserChangedResponse);
    }
  }, [getUserChangedResponse]);

  useEffect(() => {
    getApplications();
  }, [getApplications]);

  return (
    <div>
      <Card className={`${classes.card} 'NewUser-Card'`}>
        <Tooltip
          id="button-report"
          title="Atrás"
          style={{ float: "left", margin: "17px" }}
        >
          <IconButton
            area-label="Report"
            onClick={() => {
              backButton();
              validator.current.hideMessages();
              forceUpdate(0);
            }}
          >
            <ArrowIcon className={classes.previousArrow} />
          </IconButton>
        </Tooltip>

        <Box m={3}>
          <Typography color="primary" variant="h5">
            {!!userId ? "Editar usuario" : "Nuevo usuario"}
          </Typography>
        </Box>

        <form id="form-save" onSubmit={(e) => saveUser(e)} autoComplete="off">
          <Box mx={5}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="user tabs"
              textColor="primary"
              indicatorColor="primary"
              className={classes.tabs}
              p={2}
            >
              <Tab label="Datos" {...a11yProps(0)} />
              <Tab label="Empresas" {...a11yProps(1)} />
              <Tab label="Roles" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Grid
                container
                justify="space-evenly"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item lg={6} md={6} sm={12}>
                  <TextField
                    id="names"
                    className={classes.textField}
                    label="Nombres"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={!!formData.names ? formData.names : ""}
                    onChange={syncChanges}
                    error={
                      validator.current.message(
                        "names",
                        formData.names,
                        "required|max:100"
                      )
                        ? true
                        : false
                    }
                    helperText={validator.current.message(
                      "names",
                      formData.names,
                      "required|max:100"
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <TextField
                    id="surnames"
                    className={classes.textField}
                    label="Apellidos"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={!!formData.surnames ? formData.surnames : ""}
                    onChange={syncChanges}
                    error={
                      validator.current.message(
                        "surnames",
                        formData.surnames,
                        "required|max:100"
                      )
                        ? true
                        : false
                    }
                    helperText={validator.current.message(
                      "surnames",
                      formData.surnames,
                      "required|max:100"
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <TextField
                    id="document"
                    className={classes.textField}
                    label="Número de documento"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={!!formData.document ? formData.document : ""}
                    onChange={syncChanges}
                    error={
                      validator.current.message(
                        "document",
                        formData.document,
                        "required|max:20"
                      )
                        ? true
                        : false
                    }
                    helperText={validator.current.message(
                      "document",
                      formData.document,
                      "required|max:20"
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <TextField
                    id="email"
                    className={classes.textField}
                    label="Correo electrónico"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={!!formData.email ? formData.email : ""}
                    onChange={syncChanges}
                    error={
                      validator.current.message(
                        "email",
                        formData.email,
                        "required|email|max:256"
                      )
                        ? true
                        : false
                    }
                    helperText={validator.current.message(
                      "email",
                      formData.email,
                      "required|email|max:256"
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <TextField
                    id="username"
                    className={classes.textField}
                    label="Nombre de usuario"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={!!formData.username ? formData.username : ""}
                    onChange={syncChanges}
                    error={
                      validator.current.message(
                        "username",
                        formData.username,
                        "required|max:256"
                      )
                        ? true
                        : false
                    }
                    helperText={validator.current.message(
                      "username",
                      formData.username,
                      "required|max:256"
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <Tooltip
                    id="button-next"
                    title="Siguiente"
                    style={{ float: "right", marginTop: "18px" }}
                  >
                    <IconButton
                      onClick={(e) => {
                        handleChangeTab(e, 1);
                      }}
                    >
                      <ArrowIcon className={classes.nextArrow} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Grid container justify="space-evenly" alignItems="stretch">
                <Grid item lg={4} md={4} sm={12}>
                  {getApplicationsResponse.map((row) => {
                    return (
                      <Button
                        className={`${classes.btnApplications} ${
                          selectedAppId === row.id
                            ? classes.categoryButtonTrue
                            : classes.categoryButtonFalse
                        }`}
                        variant="outlined"
                        key={row.id}
                        onClick={() => handleUserRoles(row.id)}
                        fullWidth
                      >
                        {row.name}
                      </Button>
                    );
                  })}
                </Grid>
                <Grid item lg={8} md={8} sm={12}>
                  <UserRoles
                    appId={selectedAppId}
                    userId={userId}
                    rolesData={rolesData}
                    setRolesData={setRolesData}
                    cambio={Math.random()}
                    setErrors={setErrors}
                  ></UserRoles>
                  {errors !== "" && (
                    <div className={classes.errorText}>
                      <Typography color="error" variant="subtitle2">
                        {errors}
                      </Typography>
                    </div>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    id="buttonSave"
                    className={classes.buttonSave}
                    margin="dense"
                    //disabled={true}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container justify="space-between" alignItems="stretch">
                <Grid item lg={12} md={12} sm={12}>
                  <UserCustomer
                    userId={userId}
                    formData={formData}
                    setFormData={setFormData}
                    setErrorsCustomer={setErrorsCustomer}
                  ></UserCustomer>
                  {errorsCustomer && (
                    <div className={classes.errorText}>
                      <Typography color="error" variant="subtitle2">
                        {"*Se requiere una empresa principal"}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Tooltip
                id="button-next"
                title="Siguiente"
                style={{ float: "right", marginBottom: "10px" }}
              >
                <IconButton
                  onClick={(e) => {
                    handleChangeTab(e, 2);
                  }}
                >
                  <ArrowIcon className={classes.nextArrow} />
                </IconButton>
              </Tooltip>
            </TabPanel>
          </Box>
        </form>
      </Card>
    </div>
  );
};

function ArrowIcon(props) {
  return (
    <SvgIcon {...props} width="18.19" height="18.19" viewBox="0 0 18.19 18.19">
      <g transform="translate(0.502 0.502)">
        <g transform="translate(401.824 165.824) rotate(180)">
          <g transform="translate(384.637 148.637)">
            <path
              style={{
                fill: "#fff",
                strokeWidth: "1.003px",
              }}
              d="M8.593,0A8.593,8.593,0,1,1,0,8.593,8.593,8.593,0,0,1,8.593,0Z"
              transform="translate(0 0)"
            />
            <path
              style={{ fill: "none", strokeWidth: "2px" }}
              d="M385.932,3994l-3.577,3.577,3.577,3.576"
              transform="translate(-375.674 -3988.953)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

function TabPanel(props) {
  const { children, value, index, inputRef, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

UserForm.propTypes = {
  getApplications: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getApplicationsResponse: state.userReducer.getApplicationsResponse,
  getUserResponse: state.userReducer.getUserResponse,
  createUser: state.userReducer.createUser,
  getUserChangedResponse: state.userReducer.getUserChangedResponse,
  setUserErrorResponse: state.userReducer.setUserErrorResponse,
});

const mapDispatchToProps = {
  getApplications,
  getUserById,
  createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
