import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { getMenu } from "../../actions/menu.action";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  margin: {
    margin: theme.spacing(1),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#F1F1F1 0% 0% no-repeat padding-box",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: "65px",
    width: drawerWidth,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #FFEACACC 99%, #FFEACACC 100%) 0% 0% no-repeat padding-box",
  },
  content: {
    flexGrow: 1,
    padding: "0px",
  },
  listItemRoot: {
    "&$listItemSelected": {
      color: theme.palette.primary.main,
    },
    "&$listItemIconSelected": {
      color: theme.palette.primary.main,
    },
  },
  listItemSelected: {},
  listItemIconRoot: {
    //color: "white",
    "&$listItemIconSelected": {
      color: theme.palette.primary.main,
    },
  },

  listItemIconSelected: {},
  /*icon: {
    "&$iconSelected, &$iconSelected:focus, &$iconSelected:hover": {
      backgroundColor: "red",
      color: "white", //color: theme.palette.primary.main,
    },
    "&$selected, &$selected:hover, &$selected:focus": {
      color: "red",
    },
  },*/
  divider: {
    backgroundColor: "#C1C1C1",
    marginLeft: "13px",
    marginRight: "12px",
    height: "1px",
  },
  imageApp: {
    width: "163px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "43px",
    backgroundImage: theme.logo.backgroundImage,
    paddingTop: "14px",
    flex: 1,
  },
  userName: {
    flex: 1,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "right",
    paddingRight: "8px",
    color: "#6D6E71",
    textTransform: "capitalize",
  },
  customerName: {
    flex: 1,
    fontSize: "12px",
    textAlign: "right",
    color: "#6D6E71",
    textTransform: "capitalize",
  },
}));

const MasterPage = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { menuReducer, getMenu } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const userid = "";
  let userName = "",
    userSurname = "";

  const drawUserName = () => {
    if (localStorage.getItem("userTokenData")) {
      userName = JSON.parse(localStorage.getItem("userTokenData")).name;
      userSurname = JSON.parse(localStorage.getItem("userTokenData")).surname;
      return `${userName} ${userSurname}`;
    } else {
      return;
      //Redirect to login
    }
  };

  const drawCustomerName = () => {
    return JSON.parse(localStorage.getItem("userTokenData")).customerName;
  };

  const drawAvatarletters = () => {
    return `${userName
      .toUpperCase()
      .charAt(0)}${userSurname.toUpperCase().charAt(0)}`;
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (userid !== null) {
      getMenu();
    }
  }, [getMenu, userid]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawMenu = () => {
    if (menuReducer.length > 0) {
      console.log("draw menu");

      return menuReducer.map((menu, index) => (
        <div key={menu.id.toString()}>
          <ListItem
            button
            component={Link} //shows: findDOMNode is deprecated in StrictMode
            to={menu.link}
            selected={selectedIndex === menu.id}
            onClick={(event) => handleListItemClick(event, menu.id)}
            classes={{
              root: classes.listItemRoot,
              selected: classes.listItemSelected,
            }}
          >
            <ListItemIcon
            /* classes={{
                root: classes.listItemIconRoot,
                selected: classes.listItemIconSelected,
              }}*/
            //className={classes.icon}
            >
              {menu.icon === "People" ? <PeopleIcon /> : ""}
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography>{menu.description}</Typography>}
            ></ListItemText>
          </ListItem>
          <Divider className={classes.divider} />
        </div>
      ));
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={`${classes.appBar} 'App-Bar'`}>
        <Toolbar disableGutters={true}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon className={classes.icon} />
          </IconButton>
          <div>
            <div className={classes.imageApp}></div>
          </div>
          <Typography className={classes.userName} noWrap>
            {drawUserName()} <br />
            <Typography className={classes.customerName} noWrap>
              {drawCustomerName()}
            </Typography>
          </Typography>

          <Avatar alt="">{drawAvatarletters()}</Avatar>
        </Toolbar>
      </AppBar>
      <nav
        className={`${classes.drawer} 'custom-drawer'`}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div>
              <List>
                {
                  //drawMenu()
                }
              </List>
            </div>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawMenu()}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Component {...props} />
      </main>
    </div>
  );
};

MasterPage.propTypes = {
  getMenu: PropTypes.func.isRequired,
  container: PropTypes.any,
};

const mapStateToProps = (state) => ({
  menuReducer: state.menuReducer.getMenuResponse,
});

const mapDispatchToProps = {
  getMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
