import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import MasterPage from "../components/masterPage/masterPage.component.js";
import { validateTokenAction, isValidToken, refreshToken, tokenValidationTime } from "../actions/auth.action";
/**
 * PrivateRoute function (Intercepts all private routes with token verification service consumption,
 * on success and valid response, return a React Fragment with component and sidenav,
 * on fail, return a login redirect, on loading return a centered spinner)
 *
 * @export function
 * @param {Component} PrivateRoute
 * @param {String} roleNames
 * @param {String} location
 * @returns React.Fragment, Redirect
 */

const PrivateRoute = ({ component: Component, appName, ...rest }) => {
  const dispatch = useDispatch();
  const jwtToken = localStorage.getItem("jwtToken");
  const paramToken = window.location.search.substring(1);
  //console.log("paramToken", paramToken, "token", jwtToken);
  let isAuthenticated = true;
  if (paramToken && !jwtToken) {
    dispatch(validateTokenAction(paramToken));
  } else {
    if (jwtToken) {
      if (isValidToken(jwtToken)) {
        if(tokenValidationTime(jwtToken)){
          // Refrescar token        
          let userName = JSON.parse(localStorage.getItem("userTokenData")).name;
          let refreshData = {
            Token: jwtToken,
            UserName: userName,
            Appname: appName,
          };
          dispatch(refreshToken(refreshData));
        }
      } else {
        isAuthenticated = false;
      }
    } else {
      isAuthenticated = false;
    }
    //console.log("isAuthenticated", isAuthenticated);
  }

  //revisar si se deja activo el isauthenticated
  /*const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  console.log("isAuthenticated", isAuthenticated);*/

  return (
    <Route
      {...rest}
      render={(componentProps) =>
        isAuthenticated ? (
          <Fragment>
            <MasterPage component={Component} {...componentProps} />
          </Fragment>
        ) : (
          <Redirect to={`/logout`} />
        )
      }
    />
  );
};

export default connect()(PrivateRoute);
