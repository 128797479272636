import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "react-addons-update";
import { getRolesList } from "../../actions/user.action";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

/**
 * UserRoles Component ( view Roles from a user )
 *
 * @export Class Component
 * @class UserRoles
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  card: {
    margin: "0px 15px",
  },
  paper: {
    width: "100%",
    padding: "23px 20px",
  },
  searchTextField: {
    width: "368px",
    maxHeight: "25px",
    paddingLeft: "23px",
  },
  tableCell: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  logo: {
    width: "87%",
    height: "37px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "6px",
    marginLeft: "22px",
    marginRight: "1px",
  },
}));

const columns = [
  { id: "description", label: "Rol", minWidth: 280 },
  {
    id: "assigned",
    label: "Estado",
    minWidth: 100,
    align: "center",
    component: "Switch",
  },
];
const UserRoles = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const {
    appId,
    userId,
    rolesData,
    setRolesData,
    setErrors,
    getRolesListResponse,
    getRolesList,
    cambio,
  } = props;
  let appIdSelected = appId;
  let userIdSelected = userId;

  const [enteredRolesFilter, setEnteredRolesFilter] = useState("");
  const [allRoles, setAllRoles] = useState(false);

  const handleChangeChecked = (event) => {
    if (event.target.checked === true) {
      setErrors("");
    }
    //Buscar la posición del array en que se encuentra el role
    let indexRol = rolesData[appIdSelected].findIndex(
      (role) => role.name === event.target.name
    );
    setRolesData(
      update(rolesData, {
        [appIdSelected]: {
          [indexRol]: {
            $merge: { assigned: event.target.checked },
          },
        },
      })
    );
  };

  const handleAllRolesChecked = (event) => {
    let variable = [];
    if (event.target.checked === true) {
      setErrors("");
    }

    rolesData[appIdSelected].forEach((role, index) =>
      variable.splice(index, 0, { $merge: { assigned: event.target.checked } })
    );
    console.log("handleAllRolesChecked", variable);

    setRolesData(
      update(rolesData, {
        [appIdSelected]: variable,
      })
    );
  };

  const finishGetRolesList = (data) => {
    console.log("finishGetRolesList", data);
    setRolesData((prevState) => ({
      ...prevState,
      [appIdSelected]: data,
    }));
  };

  useEffect(() => {
    setEnteredRolesFilter("");
    setAllRoles(false);
  }, [cambio]);

  useEffect(() => {
    console.log("getRoles useeffect", appIdSelected);
    /* setLoading({
      Loading: true,
      SelectId: id
    });*/
    if (appIdSelected !== 0) {
      //si no existe el state appIdSelected se llama hacia la BD
      if (!!!rolesData[appIdSelected]) {
        getRolesList(appIdSelected, userIdSelected, finishGetRolesList);
      }
    }
  }, [appIdSelected, userIdSelected, getRolesList]);

  if (appIdSelected === 0) {
    return <></>;
  }

  let appSelectedData = rolesData[appIdSelected];
  if (!!!appSelectedData) {
    console.log("no hay state, mostrar los de la base de datos");
    appSelectedData = getRolesListResponse;
  } else {
    console.log("si hay state", appSelectedData);
  }

  return (
    <div>
      <Card className={`${classes.card} 'UserRoles-Card'`}>
        <Grid container justify="space-evenly" alignItems="center" spacing={1}>
          <Grid item lg={3} md={3} sm={12}>
            <div
              className={classes.logo}
              style={{ backgroundImage: `url('${appIdSelected}Logo.png')` }}
              alt="Logo"
            ></div>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              name="RolesFilter"
              value={enteredRolesFilter}
              onChange={(e) => setEnteredRolesFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6}>
            <Box flexWrap="wrap">
              <Typography variant="subtitle2" align="right">
                Asignar todos los roles
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={1} md={1} sm={6}>
            <Tooltip title="Seleccionar todos los roles">
              <Switch
                name="allRoles"
                size="small"
                onChange={handleAllRolesChecked}
                checked={allRoles}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="roles table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      className={classes.tableCell}
                    >
                      <Typography> {column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {appSelectedData
                  .filter(
                    (num) =>
                      num.description
                        .toLowerCase()
                        .indexOf(enteredRolesFilter.toLowerCase()) >= 0 ||
                      enteredRolesFilter === ""
                  )
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.component ? (
                                <Switch
                                  name={row.name}
                                  size="small"
                                  checked={value}
                                  onChange={handleChangeChecked}
                                />
                              ) : (
                                <Typography>
                                  {column.format ? column.format(value) : value}
                                </Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Card>
    </div>
  );
};

UserRoles.propTypes = {
  getRolesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRolesListResponse: state.userReducer.getRolesListResponse,
});

const mapDispatchToProps = {
  getRolesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
