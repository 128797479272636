import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import {
  GET_USERS,
  GET_USER,
  GET_ERRORS,
  DEFAULTRESPONSE,
  GET_APPLICATIONS,
  GET_USER_CHANGED,
  SET_USER_ERROR,
} from "./types";

export /**
 * User Action getUsers
 *
 * @method GET
 * @param {*}
 */
const getUsers = () => async (dispatch) => {
  console.log("getUsers");
  try {
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/user",
      defaultHeaders()
    );
    dispatch({
      type: GET_USERS,
      payload: response.data.result.records,
    });
  } catch (error) {
    console.log(error);
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getUserById
 *
 * @method GET
 * @param {*} userId
 */
const getUserById = (userId) => async (dispatch) => {
  if (userId === 0) {
    dispatch({
      type: GET_USER,
      payload: [],
    });
  } else {
    try {
      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        pid: userId,
      });
      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/user/GetById",
        myConfig
      );
      console.log(response);
      if (response.data.result.count === 0) {
        return;
      }
      dispatch({
        type: GET_USER,
        payload: response.data.result.records[0],
      });
    } catch (error) {
      const { response } = error;
      console.log(error);
      dispatch({
        type: GET_ERRORS,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  }
};

export /**
 * User Action inactivate ( inactivate  )
 *
 * @method POST
 * @param {*} userData
 */
const inactivate = (userData, updateUsersTable) => async (dispatch) => {
  console.log(userData);
  //changeLoading(dispatch, true);
  await axios
    .post(
      API_SECURITY_ENDPOINT + "/api/user/Inactivate",
      userData,
      defaultHeaders()
    )
    .then((response) => {
      const { data } = response;
      console.log(data);
      updateUsersTable();
      /* dispatch({
        type: SET_USER_STATE,
        payload: data,
      });*/
    })
    .catch((error) => {
      console.log(error);
      const { response } = error;
      if (response) {
        dispatch({
          type: GET_ERRORS,
          payload: !!response
            ? response.data
            : DEFAULTRESPONSE.noResponseFromApi,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: DEFAULTRESPONSE.noResponseFromApi,
        });
      }
    });
};

export /**
 * User Action getApplication
 *
 * @method GET
 * @param {*}
 */
const getApplications = () => async (dispatch) => {
  try {
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/application",
      defaultHeaders()
    );
    console.log(response);
    dispatch({
      type: GET_APPLICATIONS,
      payload: response.data.result.records,
    });
  } catch (error) {
    const { response } = error;
    console.log(error);
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getCustomers
 *
 * @method GET
 * @param {*}
 */
const getCustomers = (userId, finishGetCustomers) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      userid: userId === 0 ? "" : userId,
    });
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/user/GetCustomer",
      myConfig
    );

    console.log(response);
    if (response.data.result.customers.length > 0) {
      //Transformar los datos a como los espera la vista
      var customers = response.data.result.customers;
      customers.forEach((customer) => {
        if (customer.principal === true) {
          customer.assigned = false;
        }
      });
      finishGetCustomers(customers);
    }

    /*dispatch({
      type: GET_CUSTOMERS,
      payload: response.data.result.customers,
    });*/
  } catch (error) {
    const { response } = error;
    console.log(error);
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getRolesList
 *
 * @method GET
 * @param {*}
 */
const getRolesList = (appId, userId, finishGetRolesList) => async (
  dispatch
) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      application: appId,
      user: !!!userId ? "" : userId,
    });

    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/role/list",
      myConfig
    );
    console.log(response);
    if (response.data.result.count === 0) {
      return;
    }
    finishGetRolesList(response.data.result.records);
    /*dispatch({
      type: GET_ROLES_LIST,
      payload: response.data.result.records,
    });*/
  } catch (error) {
    const { response } = error;
    console.log(error);
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action createUser ( create/modify User  )
 *
 * @method POST
 * @param {history} history
 */
const createUser = (action, userData, history) => async (dispatch) => {
  console.log(action, userData, history);
  //changeLoading(dispatch, true);
  try {
    var response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/" + action,
      userData,
      defaultHeaders()
    );
    const { data } = response;

    console.log(data);
    if (history) {
      history.push("/users", {
        message: `El usuario fue ${
          action === "create" ? "creado" : "modificado"
        } exitosamente`,
      });
    }
    dispatch({
      type: GET_USER_CHANGED,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    const response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: SET_USER_ERROR,
      payload: response,
    });
  }
  /*await axios
    .post(
      API_SECURITY_ENDPOINT + "/api/user/" + action,
      userData,
      defaultHeaders()
    )
    .then((response) => {
      const { data } = response;

      if (data.statusCode === 200) {
        console.log(data);
        if (history) {
          history.push("/users", {
            message: `El usuario fue ${
              action === "create" ? "creado" : "modificado"
            } exitosamente`,
          });
        }
      }
      //updateUsersTable();
    })
    .catch((error) => {
      console.log(error);
      const { response } = error;
      if (response) {
        dispatch({
          type: GET_ERRORS,
          payload: !!response
            ? response.data
            : DEFAULTRESPONSE.noResponseFromApi,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: DEFAULTRESPONSE.noResponseFromApi,
        });
      }
    });*/
};
