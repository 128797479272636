import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "react-addons-update";
import { getCustomers } from "../../actions/user.action";
import AlertConfirm from "../../components/alertConfirm.component";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

/**
 * UserCustomer Component ( view customer from a user )
 *
 * @export Class Component
 * @class UserCustomer
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "0px 15px",
  },
  paper: {
    width: "100%",
    padding: "23px 20px",
  },
  filter: {
    paddingLeft: "23px",
  },
  searchTextField: {
    width: "468px",
    maxHeight: "25px",
    paddingLeft: "23px",
  },
  tableCellHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  tableCellBody: { padding: "12px" },
}));

const columns = [
  { id: "name", label: "Empresa", minWidth: 280 },
  {
    id: "principal",
    label: "Empresa principal",
    minWidth: 90,
    align: "center",
    component: "Switch",
  },
  {
    id: "assigned",
    label: "Empresa asociada",
    minWidth: 90,
    align: "center",
    component: "Switch",
  },
];
const UserCustomer = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const {
    userId,
    formData,
    setFormData,
    setErrorsCustomer,
    getCustomers,
    getCustomersResponse,
  } = props;

  console.log("getCustomersResponse", getCustomersResponse);
  const [enteredCustomerFilter, setEnteredCustomerFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmPrincipal, setConfirmPrincipal] = useState(false);
  const [checked, setChecked] = useState({
    index: "",
    name: "",
    checked: false,
  });

  const handleConfirmCustomer = (event, index) => {
    console.log(
      event.target.name,
      event.target.checked,
      "index",
      index,
      "rowsPerPage",
      rowsPerPage,
      "page",
      page
    );
    //console.log(event.target.row);
    console.log(formData.customer);
    index = index + page * rowsPerPage;
    setChecked({
      index: index,
      name: event.target.name,
      checked: event.target.checked,
    });
    if (event.target.checked === true && event.target.name === "principal") {
      //Muestra el confirmar
      setConfirmPrincipal(true);
    } else {
      handleChangeCheckedCustomer(event, index);
    }
  };

  const handleChangeCheckedCustomer = (event, index) => {
    let name,
      checkedData,
      principalChecked = -1;
    if (index !== undefined) {
      name = event.target.name;
      checkedData = event.target.checked;
    } else {
      name = checked.name;
      checkedData = checked.checked;
      index = checked.index;
    }
    console.log(name, checkedData, index);
    if (checkedData === true && name === "principal") {
      //Busca el index de un check principal activo
      principalChecked = formData.customer.findIndex(
        (customer) => customer.principal === true
      );
      setErrorsCustomer(false);
    }
    //Sí ya se encuentra marcada como principal no permite marca de asociada
    if (
      checkedData === true &&
      name === "assigned" &&
      formData.customer[index].principal === true
    ) {
      return;
    }

    //sí existe un check de principal activo, lo desmarca
    if (principalChecked === -1) {
      setFormData(
        update(formData, {
          customer: {
            [index]: {
              $merge: { [name]: checkedData },
            },
          },
        })
      );
    } else {
      setFormData(
        update(formData, {
          customer: {
            [index]: {
              $merge: { [name]: checkedData },
            },
            [principalChecked]: {
              $merge: { principal: false },
            },
          },
        })
      );
    }

    setConfirmPrincipal(false);
  };

  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const finishGetCustomers = (data) => {
    //Se puede eliminar la funcion si  se detecta donde justo termina de cargar los roles.
    console.log("finishGetCustomers", data);
    setFormData((prevState) => ({
      ...prevState,
      customer: data,
    }));
    /*setFormData((prevState) => ({
      ...prevState,
      customer: data,
    }));*/
  };

  useEffect(() => {
    console.log("getCustomers useeffect", formData.customer);
    if (!!!formData.customer || formData.customer.length === 0) {
      getCustomers(userId, finishGetCustomers);
    }
  }, [userId, getCustomers]);

  let customerData = formData.customer;
  if (!!!customerData) {
    //console.log("no hay state, mostrar los de la base de datos");
    customerData = getCustomersResponse;
  } else {
    //console.log("si hay state", customerData);
  }
  return (
    <div>
      <Card className={`${classes.card} 'UserCustomer-Card'`}>
        <Grid container justify="flex-start" className={classes.filter}>
          <Grid item lg={8} md={8} sm={12}>
            <TextField
              name="CustomersFilter"
              value={enteredCustomerFilter}
              onChange={(e) => setEnteredCustomerFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item lg={2} md={2} sm={false}></Grid>
        </Grid>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table stickyHeader aria-label="customers table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      className={classes.tableCellHeader}
                    >
                      <Typography> {column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customerData
                  .filter(
                    (num) =>
                      num.name
                        .toLowerCase()
                        .indexOf(enteredCustomerFilter.toLowerCase()) >= 0 ||
                      enteredCustomerFilter === ""
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                              className={classes.tableCellBody}
                            >
                              {column.component ? (
                                <Switch
                                  //id={row.name}
                                  name={column.id}
                                  size="small"
                                  checked={value}
                                  onChange={(e) => {
                                    handleConfirmCustomer(e, index);
                                  }}
                                />
                              ) : (
                                <Typography>
                                  {column.format ? column.format(value) : value}
                                </Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            style={{ display: "flex", justifyContent: "center" }}
            count={
              customerData.filter(
                (num) =>
                  num.name
                    .toLowerCase()
                    .indexOf(enteredCustomerFilter.toLowerCase()) >= 0 ||
                  enteredCustomerFilter === ""
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} de ${count}`
            }
            labelRowsPerPage="Filas por página"
            nextIconButtonText="Siguiente"
            backIconButtonText="Anterior"
          />
        </Paper>
      </Card>

      {confirmPrincipal && (
        <AlertConfirm
          isOpen={confirmPrincipal}
          mensajeModal={`¿Desea cambiar la empresa principal?`}
          handleAccept={(e) => {
            handleChangeCheckedCustomer();
          }}
          showBtnAccept={true}
          showBtnCancel={true}
          onCancel={true}
          onClose={() => setConfirmPrincipal(false)}
        />
      )}
    </div>
  );
};

UserCustomer.propTypes = {
  getCustomers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getCustomersResponse: state.userReducer.getCustomersResponse,
});

const mapDispatchToProps = {
  getCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCustomer);
