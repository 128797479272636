import React from "react";
import { Provider } from "react-redux";

import store from "./store/store";
import Routes from "./routes/routes";
import { APP_HEADER_NAME } from "./config/config";

import { ThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "./utils/muiTheme";
import { themeColors } from "./utils/themeColors";

//class App extends Component {
const App = () => {
  var appName = "";

  var request = new XMLHttpRequest();
  request.open("GET", document.location, false);
  request.send(null);
  appName = request.getResponseHeader(APP_HEADER_NAME);
  if (!appName || !themeColors[appName]) {
    appName = "ibuhoo";
  }

  var favicon = document.getElementById("favicon");
  favicon.href = `/${appName}Ico.ico`;

  document.title = appName.charAt(0).toUpperCase() + appName.slice(1);

  return (
    <ThemeProvider theme={muiTheme(appName)}>
      <Provider store={store}>
        <Routes appName={appName}></Routes>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
