import { SET_CURRENT_USER } from "../actions/types";

const initialState = {
  getMenuResponse: [],
};

/**
 * MenuReducer (set user Menu or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        getMenuResponse: action.payload,
      };
    default:
      return state;
  }
}
