import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getUsers, inactivate } from "../../actions/user.action";
import UserForm from "../users/userForm.view";
import { makeStyles } from "@material-ui/core/styles";

//import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
//import SortIcon from "@material-ui/icons/Sort";
import SvgIcon from "@material-ui/core/SvgIcon";

import PageBackground from "../../components/pageBackground.component";
import AlertConfirm from "../../components/alertConfirm.component";
/**
 * Users Component ( full view for list of users)
 *
 * @export Class Component
 * @class Users
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "13px 0px 10px 20px",
  },
  card: {
    margin: "0px 15px",
  },
  paper: {
    width: "100%",
    padding: "33px 20px",
  },
  searchTextField: {
    width: "468px",
    maxHeight: "25px",
    padding: "10px 23px",
  },
  btnNewUser: {
    flex: 1,
    textAlign: "right",
    paddingRight: "8px",
    width: "32px",
    height: "32px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 2px #0000003D",
    border: "1px solid #FFB532",
    opacity: "1",
  },
  showCardFalse: { display: "none" },
  showCardTrue: { display: "block" },
  buttonSort: {
    marginTop: "21px",
  },
  newUsericon: {
    stroke: theme.palette.secondary.main,
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main + "D4", // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  buttonNewUser: { float: "right", paddingRight: "32px" },
}));

const columns = [
  { id: "names", label: "Nombres", minWidth: 140, align: "center" },
  { id: "surnames", label: "Apellidos", minWidth: 140, align: "center" },
  {
    id: "document",
    label: "Documento\u00a0",
    minWidth: 100,
    align: "center",
    format: (value) =>
      !isNaN(value) ? parseFloat(value).toLocaleString("es-CO") : value,
  },
  {
    id: "email",
    label: "Correo\u00a0electrónico",
    minWidth: 170,
    align: "center",
  },
  {
    id: "idstate",
    label: "Estado",
    minWidth: 90,
    align: "center",
    component: "Switch",
    format: (value) => (value === 1 ? false : true),
  },
  /*{
    id: "createdon",
    label: "Fecha creación",
    minWidth: 100,
    format: (value) => value.substring(0, 16), //new Date(value),
  },*/
];

const Users = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const { userReducer, getUsers, inactivate } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checked, setChecked] = useState(null); //Utilizado solo para actualizar tabla users
  const [enteredFilter, setEnteredFilter] = useState("");
  const [cardState, setCardState] = useState(true);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    checked: false,
  });
  const [showUserAlert, setShowUserAlert] = useState(false);

  const [userId, setUserId] = useState(0);
  const [userChanged, setUserChanged] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleConfirmClick = (e, userid) => {
    setConfirmInactivate({
      open: true,
      item: userid,
      checked: e.target.checked,
    });
  };

  const handleConfirmChecked = () => {
    inactivate({ id: confirmInactivate.item }, updateUsersTable);
    setConfirmInactivate({
      open: false,
      checked: false,
    });
  };

  const updateUsersTable = () => {
    /* setLoading({
      Loading: true,
      SelectId: id
    });*/
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    console.log("userChanged useEffect");
    getUsers();
  }, [userChanged]);

  useEffect(() => {
    if (checked !== null) {
      getUsers(); //eliminarlo, se debe recargar el state
      //setChecked(checked);
    }
  }, [getUsers, checked]);

  return (
    <div>
      <PageBackground></PageBackground>
      <div className={classes.title}>Usuarios</div>

      <Card
        className={`${classes.card} 'User-Card' ${
          cardState ? classes.showCardTrue : classes.showCardFalse
        }`}
      >
        <TextField
          className={classes.searchTextField}
          value={enteredFilter}
          autoFocus={true}
          onChange={(e) => setEnteredFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          margin="dense"
        />
        {/* <Button
          className={classes.buttonSort}
          variant="outlined"
          id="buttonSort"
          onClick={() => null}
          margin="dense"
          startIcon={<SortIcon />}
        >
          Ordenar
        </Button> */}
        <p className={classes.buttonNewUser}>
          <Tooltip title="Crear Usuario" aria-label="Nuevo usuario">
            <Fab
              color="secondary"
              size="small"
              className={classes.fab}
              onClick={() => {
                console.log("cardState", cardState);
                setCardState(true);
                if (cardState === true) {
                  setUserId(0);
                  setCardState(false);
                }
              }}
            >
              <NewUserIcon className={classes.newUsericon} />
            </Fab>
          </Tooltip>
        </p>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table stickyHeader aria-label="users table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                      className={classes.tableCell}
                    >
                      <Typography> {column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userReducer
                  .filter(
                    (num) =>
                      num.names
                        .toLowerCase()
                        .indexOf(enteredFilter.toLowerCase()) >= 0 ||
                      num.document.indexOf(enteredFilter) >= 0 ||
                      num.email
                        .toLowerCase()
                        .indexOf(enteredFilter.toLowerCase()) >= 0 ||
                      enteredFilter === ""
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (!column.component) {
                                  setCardState(true);
                                  if (cardState === true) {
                                    setCardState(false);
                                    setUserId(row.id);
                                  }
                                }
                              }}
                            >
                              {column.component ? (
                                <Tooltip
                                  title="Activar/Inactivar Usuario"
                                  aria-label="Activar/Inactivar Usuario"
                                >
                                  <Switch
                                    name={row.id}
                                    size="small"
                                    color="primary"
                                    checked={column.format(value)}
                                    onChange={(e) => {
                                      handleConfirmClick(e, row.id);
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <Typography>
                                  {column.format ? column.format(value) : value}
                                </Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            style={{ display: "flex", justifyContent: "center" }}
            count={
              userReducer.filter(
                (num) =>
                  num.names
                    .toLowerCase()
                    .indexOf(enteredFilter.toLowerCase()) >= 0 ||
                  num.document.indexOf(enteredFilter) >= 0 ||
                  num.email
                    .toLowerCase()
                    .indexOf(enteredFilter.toLowerCase()) >= 0 ||
                  enteredFilter === ""
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} de ${count}`
            }
            labelRowsPerPage="Filas por página"
            nextIconButtonText="Siguiente"
            backIconButtonText="Anterior"
          />
        </Paper>
      </Card>

      <div
        className={!cardState ? classes.showCardTrue : classes.showCardFalse}
      >
        <UserForm
          userId={userId}
          setCardState={setCardState}
          history={props.history}
          setShowUserAlert={setShowUserAlert}
          setUserChanged={setUserChanged}
        ></UserForm>
      </div>

      {confirmInactivate.open && (
        <AlertConfirm
          isOpen={confirmInactivate.open}
          mensajeModal={`¿Desea ${
            confirmInactivate.checked ? "activar" : "desactivar"
          } este usuario?`}
          handleAccept={handleConfirmChecked}
          showBtnAccept={true}
          showBtnCancel={true}
          onClose={() =>
            setConfirmInactivate({
              open: false,
              checked: false,
            })
          }
        />
      )}

      {showUserAlert &&
        !!props.history.location.state &&
        !!props.history.location.state.message && (
          <AlertConfirm
            isOpen={!!props.history.location.state}
            mensajeModal={props.history.location.state.message}
            showBtnAccept={false}
            onClose={() => setShowUserAlert(false)}
            fadeOut={true}
          ></AlertConfirm>
        )}
    </div>
  );
};

function NewUserIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 39 34"
      style={{ width: "1.9em", height: "2.1em" }}
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="39.003"
          height="39.003"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1" result="b" />
          <feFlood floodOpacity="0.239" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(3.502 1.502)">
        <g
          style={{ filter: 'url("#a")' }}
          transform="matrix(1, 0, 0, 1, -3.5, -1.5)"
        >
          <path
            style={{ fill: "#fff" }}
            d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
            transform="translate(3.5 1.5)"
          />
        </g>
        <g transform="translate(9.685 9.685)">
          <line
            style={{
              fill: "none",
              strokeMiterlimit: 10,
              strokeWidth: "2.4px",
            }}
            y2="12.63"
            transform="translate(6.315)"
          />
          <line
            style={{
              fill: "none",
              strokeMiterlimit: 10,
              strokeWidth: "2.4px",
            }}
            x2="12.63"
            transform="translate(0 6.315)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  inactivate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer.getUsersResponse,
  inactivate: state.userReducer.inactivateResponse,
});

const mapDispatchToProps = {
  getUsers,
  inactivate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
