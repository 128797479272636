import {
  SET_CURRENT_USER,
  SET_IS_AUTHENTICATED,
  SET_APP_NAME,
  SET_AUTH_STATUS,
  SET_USER_INFO,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  userInfo: undefined,
  appName: undefined,
  //getAuthenticationResponse: false,
  //user: {},
  //respuesta: {},
};

/**
 * AuthReducer (set loggedIn user or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        getAuthenticationResponse: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_APP_NAME:
      return {
        ...state,
        appName: action.payload,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
