//import axios from "axios";
import { TOKEN_PREFIX } from "../config/config";

/**
 * assignAuthToken constant function (verify if token exists and set this to axios Authorization header and localStorage Token,
 * else, remove axios authorization header and localStorage Token)
 *
 * @export constant
 * @param {*} token
 * @returns boolean
 */
const assignAuthToken = (token) => {
  if (token) {
    localStorage.setItem("jwtToken", TOKEN_PREFIX + token);
  } else {
    localStorage.removeItem("jwtToken");
  }
};

export default assignAuthToken;
