import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { loginUser } from "../../actions/auth.action";

import PageBackground from "../../components/pageBackground.component";
import CardBody from "../../components/CardBody/cardBody.component";
import InputForm from "../../components/InputForm/inputForm.component";
import PrimaryButton from "../../components/primaryButton.component";
import ErrorBox from "../../components/errorBox.component";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Login Component ( full view Login component, render a login
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class Login
 * @extends {Component}
 * @returns Redux connect
 */

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { username: "", password: "" },
      getAuthenticationResponse: {
        responseCode: "",
      },
      loading: false,
      getErrors: "",
    };
    this.loginValidator = new SimpleReactValidator(reactValidatorOptions);
  }

  setLogin = (e) => {
    e.preventDefault();
    if (this.loginValidator.allValid()) {
      const { username, password } = this.state.form;
      let userData = {
        user: username,
        password: password,
        appname: this.props.appName,
        page: window.location.search.substring(1),
      };
      this.props.loginUser(userData, this.props.history);
    } else {
      this.loginValidator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    let appName = this.props.appName;

    return (
      <>
        <PageBackground></PageBackground>
        <CardBody title="Login">
          <form
            id="login-validation"
            onSubmit={(e) => this.setLogin(e)}
            autoComplete="off"
          >
            <hr></hr>
            <InputForm
              placeholder={"Nombre usuario"}
              icon={"Person"}
              id="username"
              onChange={this.syncChanges}
              value={this.state.form.username}
              validator={this.loginValidator}
              validateOptions={"required"}
            />
            <br />
            <InputForm
              placeholder={"Contraseña"}
              icon={"Lock"}
              type={"password"}
              id="password"
              onChange={this.syncChanges}
              value={this.state.form.password}
              validator={this.loginValidator}
              validateOptions={"required"}
            />
            <br />
            <PrimaryButton
              txtBtn={"Ingresar"}
              loading={this.state.loading}
            ></PrimaryButton>

            {this.state.getErrors && (
              <ErrorBox value={this.state.getErrors}></ErrorBox>
            )}

            <hr></hr>
            <Typography style={{ textAlign: "center" }}>
              <Link href="/resetPassword" variant="body1">
                Recordar contraseña
              </Link>
              {appName === "colfactura" ? (
                <>
                  <br />
                  <Link href="/" variant="body1">
                    Ver Demo
                  </Link>
                  <br />
                  <Link href="/" variant="body1">
                    Comprar Colfactura
                  </Link>
                </>
              ) : null}
            </Typography>
          </form>
        </CardBody>
      </>
    );
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (
      !!nextProps.authReducer.getAuthenticationResponse &&
      nextProps.authReducer.getAuthenticationResponse !==
        state.getAuthenticationResponse
    ) {
      update.getAuthenticationResponse =
        nextProps.authReducer.getAuthenticationResponse;
    }
    if (nextProps.loadingReducer.loading !== state.loading) {
      update.loading = nextProps.loadingReducer.loading;
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      this.state.loading
        ? this.setState({ loading: true })
        : this.setState({ loading: false });
    }
    if (
      prevState.getAuthenticationResponse !==
      this.state.getAuthenticationResponse
    ) {
      if (this.state.getAuthenticationResponse.responseCode === 200) {
        this.cleanLoginForm();
      } else {
        this.setState({
          getErrors: this.state.getAuthenticationResponse.message,
        });
      }
      console.log(this.state.getAuthenticationResponse.responseCode);
    }
  }

  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
      getErrors: "",
    }));
  };

  cleanLoginForm = () => {
    this.setState((prevState) => ({
      form: { ...prevState.form, username: "", password: "" },
      getAuthenticationResponse: {
        responseCode: "",
      },
      getErrors: "",
    }));
    this.loginValidator.hideMessages(); //this.forceUpdate();
  };
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
});

export default connect(mapStateToProps, { loginUser })(Login);
