import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../views/dashboard/Home";
import Users from "../views/users/users.view";
import Login from "../views/auth/login.view";
import ResetPassword from "../views/auth/resetPassword.view";
import ChangePassword from "../views/auth/changePassword.view";
import Alert from "../views/Alert";
import Logout from "../views/auth/logout.view";
import PrivateRoute from "./PrivateRoute";
import { validateTokenAction } from "../actions/auth.action";

let history = createBrowserHistory();

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */

class Routes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Login {...this.props} history={history} isAuthed={true} />
              )}
            ></Route>
            <Route
              path="/resetpassword"
              exact
              render={(props) => (
                <ResetPassword
                  {...this.props}
                  history={history}
                  isAuthed={true}
                />
              )}
            ></Route>
            <Route
              path="/changepassword"
              exact
              render={(props) => (
                <ChangePassword
                  {...this.props}
                  history={history}
                  isAuthed={true}
                />
              )}
            ></Route>
            <Route
              path="/login"
              exact
              render={(props) => (
                <Login {...this.props} history={history} isAuthed={true} />
              )}
            ></Route>
            <Route path="/logout" exact component={Logout}></Route>
            <Route path="/alert" exact component={Alert}></Route>

            <PrivateRoute path="/home" exact component={Home} appName={this.props.appName} />
            <PrivateRoute path="/users" exact component={Users} appName={this.props.appName} />
          </Switch>
        </Router>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  validateTokenAction,
})(Routes);
