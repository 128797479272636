export const themeColors = {
  colfactura: {
    primaryColor: "#025BA5",
    onhoverPrimaryColor: "#50CBFF",
    secondaryColor: "#06B4EA",
  },
  firmaya: {
    primaryColor: "#FA5A00",
    onhoverPrimaryColor: "#FFAB8C",
    secondaryColor: "#FFB532",
  },
  ibuhoo: {
    primaryColor: "#FA5A00",
    onhoverPrimaryColor: "#FFAB8C",
    secondaryColor: "#FFB532",
  },
};
