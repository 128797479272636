import {
  GET_USERS,
  GET_USER,
  SET_USER_STATE,
  GET_APPLICATIONS,
  GET_CUSTOMERS,
  GET_USER_CHANGED,
  SET_USER_ERROR,
} from "../actions/types";

const initialState = {
  getUsersResponse: [],
  getUserResponse: [],
  inactivateResponse: [],
  getApplicationsResponse: [],
  getRolesListResponse: [],
  getCustomersResponse: [],
};

/**
 * MenuReducer (set user Menu or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        getUsersResponse: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        getUserResponse: action.payload,
      };
    case GET_USER_CHANGED:
      return {
        ...state,
        getUserChangedResponse: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        setUserErrorResponse: action.payload,
      };
    case GET_APPLICATIONS:
      return {
        ...state,
        getApplicationsResponse: action.payload,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        getCustomersResponse: action.payload,
      };
    case SET_USER_STATE:
      return {
        ...state,
        inactivateResponse: action.payload,
      };
    default:
      return state;
  }
}
