import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  assignPassword,
  changePassword,
  tokenValidation,
} from "../../actions/auth.action";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import {
  hasNumbers,
  hasLowerLetter,
  hasUpperLetter,
  isCorrectLength,
} from "../../helpers/regex.helper";

import { encryptionKey, encryptionIv } from "../../config/config";

import PageBackground from "../../components/pageBackground.component";
import CardBody from "../../components/CardBody/cardBody.component";
import InputForm from "../../components/InputForm/inputForm.component";
import PrimaryButton from "../../components/primaryButton.component";
import ErrorBox from "../../components/errorBox.component";
import ErrorBoundary from "../../components/errorBoundary.component";

import Typography from "@material-ui/core/Typography";
import { Grid, List, ListItem } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

/**
 * Change Password Component ( change password sending an E-mail
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class Change Password
 * @extends {Component}
 * @returns Redux connect
 */

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        user: "", //viene por query string o en la peticion
        token: "", //viene por query string
        oldpassword: "",
        confirmpassword: "",
        newpassword: "",
      },
      getAuthenticationResponse: {
        responseCode: "",
      },
      getErrors: "",
      loading: "",
    };
    this.loginValidator = new SimpleReactValidator(reactValidatorOptions);
    try {
      var paramsChar = decodeURIComponent(window.location.search.substring(1));
      //console.log(window.location);
      //console.log(paramsChar);
      // The cipher-block chaining mode of operation maintains internal state, so to decrypt a new instance must be instantiated.
      var aesjs = require("aes-js");
      var aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);

      var encryptedText = aesjs.utils.hex.toBytes(
        new Buffer(paramsChar || "", "base64").toString("hex")
      );

      var decryptedBytes = aesCbc.decrypt(encryptedText);
      // console.log(encryptedText);
      // console.log(decryptedBytes);
      var decryptedText = aesjs.utils.utf8
        .fromBytes(decryptedBytes)
        .replace(/(?:\\[rn]|[\r\n]+|[\000-\031\200-\377])+/g, "");

      // console.log(decryptedText);
      var objParameters = JSON.parse(decryptedText);

      console.log(objParameters);

      this.state = {
        form: {
          user:
            props.history.location.state !== undefined
              ? props.history.location.state.user
              : objParameters.user, //viene por query string o en la peticion
          token: objParameters.token, //viene por query string
          oldpassword: "",
          confirmpassword: "",
          newpassword: "",
        },
      };
    } catch (error) {
      console.log(error);
      //Sí se elimina el push, se debe tener en cuenta
      this.props.history.push({
        pathname: "/Alert",
        state: {
          message: "El enlace no es válido.",
          pagename: "/Login",
        },
      });
      return;
    }
  }

  setChangePassword = (e) => {
    e.preventDefault();
    if (this.loginValidator.allValid()) {
      //Compare both passwords
      if (this.comparePasswords(this.state.getErrors)) {
        const { user, newpassword, token, oldpassword } = this.state.form;
        var appname = this.props.appName;

        //Sí existe el token se utiliza el método Asignar.
        if (token === "" || token === undefined) {
          let userData = {
            user: user,
            password: oldpassword,
            newpassword: newpassword,
            appname: appname,
          };
          this.props.changePassword(userData, this.props.history);
        } else {
          let userData = {
            user: user,
            password: newpassword,
            token: token,
            appname: appname,
          };
          this.props.assignPassword(userData, this.props.history);
        }
      }
    } else {
      this.loginValidator.showMessages();
      this.forceUpdate();
    }
  };

  comparePasswords = (error) => {
    if (this.state.form.newpassword !== this.state.form.confirmpassword) {
      this.setState({
        getErrors: "Las contraseñas no coinciden.",
      });
      return false;
    } else {
      let passwordValidLength = isCorrectLength(this.state.form.newpassword, 8)
        ? true
        : false;
      let passwordValidUpper = hasUpperLetter(this.state.form.newpassword)
        ? true
        : false;
      let passwordValidLower = hasLowerLetter(this.state.form.newpassword)
        ? true
        : false;
      let passValidNum = hasNumbers(this.state.form.newpassword) ? true : false;
      if (
        passwordValidLength &&
        passwordValidUpper &&
        passwordValidLower &&
        passValidNum
      ) {
        return true;
      } else {
        this.setState({
          getErrors:
            "La contraseña no cumple con el nivel mínimo de seguridad requerida.",
        });
        return false;
      }
    }
  };

  render() {
    return (
      <ErrorBoundary>
        <PageBackground></PageBackground>
        <CardBody title="Cambiar Contraseña">
          <form
            id="change-pass"
            onSubmit={(e) => this.setChangePassword(e)}
            autoComplete="off"
          >
            <hr></hr>
            <Typography>Usuario: {this.state.form.user}</Typography>
            <br />
            <InputForm
              placeholder={"Nueva Contraseña"}
              icon={"Lock"}
              type={"password"}
              id="newpassword"
              onChange={this.syncChanges}
              value={this.state.form.newpassword}
              validator={this.loginValidator}
              validateOptions={"required"}
            />
            <br />
            <InputForm
              placeholder={"Confirmar Contraseña"}
              icon={"Lock"}
              type={"password"}
              id="confirmpassword"
              onChange={this.syncChanges}
              value={this.state.form.confirmpassword}
              validator={this.loginValidator}
              validateOptions={"required"}
            />

            {this.state.getErrors && (
              <ErrorBox value={this.state.getErrors}></ErrorBox>
            )}

            <Grid>
              <br />
              <Typography>
                La contraseña debe cumplir las siguientes condiciones:
              </Typography>
              <List dense={true}>
                <ListItem>
                  {isCorrectLength(this.state.form.newpassword, 8) ? (
                    <Check style={{ fontSize: 15, color: "#00AC6C" }} />
                  ) : (
                    <Clear style={{ fontSize: 15, color: "#FF0000" }} />
                  )}
                  <Typography>Mínimo 8 caracteres </Typography>
                </ListItem>
                <ListItem>
                  {hasUpperLetter(this.state.form.newpassword) ? (
                    <Check style={{ fontSize: 15, color: "#00AC6C" }} />
                  ) : (
                    <Clear style={{ fontSize: 15, color: "#FF0000" }} />
                  )}
                  <Typography> Una letra mayúscula </Typography>
                </ListItem>
                <ListItem>
                  {hasLowerLetter(this.state.form.newpassword) ? (
                    <Check style={{ fontSize: 15, color: "#00AC6C" }} />
                  ) : (
                    <Clear style={{ fontSize: 15, color: "#FF0000" }} />
                  )}
                  <Typography>Una letra minúscula </Typography>
                </ListItem>
                <ListItem>
                  {hasNumbers(this.state.form.newpassword) ? (
                    <Check style={{ fontSize: 15, color: "#00AC6C" }} />
                  ) : (
                    <Clear style={{ fontSize: 15, color: "#FF0000" }} />
                  )}
                  <Typography>Un número </Typography>
                </ListItem>
              </List>
            </Grid>
            <PrimaryButton
              txtBtn={"Cambiar"}
              loading={this.state.loading}
            ></PrimaryButton>
          </form>
        </CardBody>
      </ErrorBoundary>
    );
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (
      !!nextProps.authReducer.getAuthenticationResponse &&
      nextProps.authReducer.getAuthenticationResponse !==
        state.getAuthenticationResponse
    ) {
      update.getAuthenticationResponse =
        nextProps.authReducer.getAuthenticationResponse;
    }
    if (
      !!nextProps.loadingReducer.loading &&
      nextProps.loadingReducer.loading !== state.loading
    ) {
      update.loading = nextProps.loadingReducer.loading;
    }
    if (
      !!nextProps.authReducer.getAuthenticationResponse &&
      nextProps.authReducer.getAuthenticationResponse !==
        state.getAuthenticationResponse
    ) {
      update.getAuthenticationResponse =
        nextProps.authReducer.getAuthenticationResponse;
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      this.state.loading
        ? this.setState({ loading: true })
        : this.setState({ loading: false });
    }
    if (
      prevState.getAuthenticationResponse !==
      this.state.getAuthenticationResponse
    ) {
      if (this.state.getAuthenticationResponse.responseCode === 200) {
        this.cleanChangeForm();
      } else {
        this.setState({
          getErrors: this.state.getAuthenticationResponse.message,
          loading: false,
        });
      }
      console.log(this.state.getAuthenticationResponse.responseCode);
      console.log(this.state.getAuthenticationResponse.message);
    }
  }

  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
      getErrors: "",
    }));
  };

  cleanChangeForm = () => {
    this.setState({
      form: {
        user: "",
        token: "",
        newpassword: "",
        confirmpassword: "",
        oldpassword: "",
      },
      getAuthenticationResponse: {
        responseCode: "",
      },
      loading: "",
    });
    this.loginValidator.hideMessages();
  };

  componentDidMount() {
    console.log(this.state);
    if (this.state.form.token) {
      this.props.tokenValidation(
        this.state.form.user,
        this.state.form.token,
        this.props.history
      );
    }
  }
}

ChangePassword.propTypes = {
  assignPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  tokenValidation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
});

export default connect(mapStateToProps, {
  assignPassword,
  changePassword,
  tokenValidation,
})(ChangePassword);
