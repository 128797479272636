import axios from "axios";
import {
  API_AUTHENTICATION,
  URL_SECURITY,
  encryptionKey,
  encryptionIv,
  TIME_REFRESH_TOKEN,
} from "../config/config";
import { SET_CURRENT_USER, LOADING, DEFAULTRESPONSE } from "./types";
import jwt_decode from "jwt-decode";
import assignAuthToken from "../utils/assignAuthToken";
import aesjs from "aes-js";
import moment from "moment";
import "moment/locale/es";

export /**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {*} userData
 */
const loginUser = (userData, history) => async (dispatch) => {
  console.log(userData);

  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/Login", userData)
    .then((response) => {
      console.log(response);
      const { data } = response;
      if (data.statusCode === 200) {
        const { urlApp } = data.result;
        console.log(urlApp);
        if (urlApp === undefined) {
          console.log(
            "Ha ocurrido un error. No se encuentra definida la url a redireccionar."
          );
        } else {
          window.location.assign(urlApp);
        }
      }

      datadispatch = authResponse(
        response.status,
        data.statusCode,
        data.result.information
      );
    })
    .catch((error) => {
      const { response } = error;
      console.log(error);
      if (response) {
        try {
          const { data } = response;
          const { informationCode } = data.result;

          if (informationCode === "R13") {
            //Se debe cambiar la clave antes de su primer acceso
            if (history)
              /*history.push({
                pathname: "/changepassword",
                state: {
                  user: userData.user,
                  oldpassword: userData.password,
                },
              });*/
              history.push({
                pathname: "/Alert",
                state: {
                  message:
                    "Por favor revise el correo de bienvenida que contiene el enlace y las instrucciones para activación de su usuario.",
                  pagename: "/Login",
                },
              });
          } else {
            //Se retorna el mensaje
            datadispatch = authResponse(
              response.status,
              !!data.statusCode ? data.statusCode : "500",
              !!data.result.information && data.statusCode !== 500
                ? data.result.information
                : DEFAULTRESPONSE.noResponseFromApi
            );
          }
        } catch {
          datadispatch = authResponse(
            500,
            500,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      } else {
        datadispatch = authResponse(
          500,
          500,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
      dispatch({
        type: SET_CURRENT_USER,
        payload: datadispatch,
      });
    });
};

export /**
 * AuthAction resetPassword ( Reset Password  )
 *
 * @method POST
 * @param {history} history
 */
const resetPassword = (userData, history) => async (dispatch) => {
  console.log(userData);
  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/login/ResetPassword", userData)
    .then((response) => {
      console.log(response);
      const { data } = response;
      if (history)
        history.push({
          pathname: "/Alert",
          state: {
            message: data.result.information,
            pagename: "/Login",
          },
        });
    })
    .catch((error) => {
      const { response } = error;
      if (response) {
        try {
          const { data } = response;
          console.log(response);

          datadispatch = authResponse(
            response.status,
            !!data.statusCode ? data.statusCode : "500",
            !!data.result.information && data.statusCode !== 500
              ? data.result.information
              : DEFAULTRESPONSE.noResponseFromApi
          );
        } catch {
          datadispatch = authResponse(
            500,
            500,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      } else {
        datadispatch = authResponse(
          500,
          500,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
      dispatch({
        type: SET_CURRENT_USER,
        payload: datadispatch,
      });
    });
};

export /**
 * AuthAction assignPassword ( assign Password  )
 *
 * @method POST
 * @param {history} history
 */
const assignPassword = (userData, history) => async (dispatch) => {
  console.log(userData);
  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/login/AssignPassword", userData)
    .then((response) => {
      console.log(response);
      const { data } = response;

      if (history)
        history.push({
          pathname: "/Alert",
          state: {
            message: data.result.information,
            pagename: "/Login",
          },
        });
    })
    .catch((error) => {
      const { response } = error;
      console.log(response);
      if (response) {
        const { data } = response;
        datadispatch = authResponse(
          response.status,
          !!data.statusCode ? data.statusCode : "500",
          !!data.result.information && data.statusCode !== 500
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi
        );
      } else {
        datadispatch = authResponse(
          500,
          500,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
      dispatch({
        type: SET_CURRENT_USER,
        payload: datadispatch,
      });
    });
};

export /**
 * AuthAction changePassword ( Change Password  )
 *
 * @method POST
 * @param {history} history
 */
const changePassword = (userData, history) => async (dispatch) => {
  console.log(userData);
  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/login/ChangePassword", userData)
    .then((response) => {
      console.log(response);
      const { data } = response;

      if (history)
        history.push({
          pathname: "/Alert",
          state: {
            message: data.result.information,
            pagename: "/Login",
          },
        });
    })
    .catch((error) => {
      const { response } = error;
      console.log(response);
      if (response) {
        const { data } = response;
        datadispatch = authResponse(
          response.status,
          !!data.statusCode ? data.statusCode : "500",
          !!data.result.information && data.statusCode !== 500
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi
        );
      } else {
        datadispatch = authResponse(
          500,
          500,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
      dispatch({
        type: SET_CURRENT_USER,
        payload: datadispatch,
      });
    });
};

export /**
 * AuthAction tokenValidation ( token Validation )
 *
 * @method GET
 * @param {history} history
 */
const tokenValidation = (user, token, history) => async (dispatch) => {
  try {
    const requestData = {
      headers: {
        user: user,
        token: token,
      },
    };
    const response = await axios.get(
      API_AUTHENTICATION + "/api/login/TokenValidation",
      requestData
    );
    dispatch({
      type: SET_CURRENT_USER,
      payload: response.data.result,
    });
  } catch (error) {
    const { response } = error;

    console.log(response);
    if (response) {
      const { data } = response;
      if (history) {
        var message = data
          ? data.result.information
          : DEFAULTRESPONSE.noResponseFromApi;
        history.push({
          pathname: "/Alert",
          state: {
            message: message,
            pagename: "/Login",
          },
        });
      }
    }
  }
};

export /**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
const logoutUser = (history) => async (dispatch) => {
  /*await axios
    .get(API_AUTHENTICATION + "/auth/logout")
    .then((res) => {
      console.log("ok");
    })
    .catch((err) => {
      console.log(err);
    });*/
  assignAuthToken(false);
  if (history)
    history.push({
      pathname: "/login",
      state: { message: "Sesión finalizada exitosamente" },
    });
};

function authResponse(httpCode, responseCode, message) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    message: message,
  };
}

function changeLoading(dispatch, status) {
  dispatch({
    type: LOADING,
    payload: status,
  });
}

/**
 * validateTokenAction Validar token
 *
 * @method validateTokenAction
 * @param {queryString} queryString
 */
export const validateTokenAction = (queryString) => async (dispatch) => {
  try {
    assignAuthToken(false);
    if (!!queryString) {
      const decodedToken = await decodeTokenData(queryString);
      await loginTokenValidation(decodedToken, dispatch);
    }
  } catch (err) {
    console.log(err);
    redirectToLogin();
  } finally {
    // detener loading
  }
};

/**
 * AuthAction loginTokenValidation ( set user credentials to server for login attempt )
 *
 * @method GET
 * @param {*} userData
 */
async function loginTokenValidation(token, dispatch) {
  try {
    const decodedTokenJwt = jwt_decode(token);
    /*const currentTime = Date.now() / 1000;
    if (decodedTokenJwt.exp < currentTime) {
      console.log("error");
      //redirectToLogin();
    }*/
    if (tokenValidity(decodedTokenJwt)) {
      console.log("Token JWT valido");
      const userTokenData = {
        userId: decodedTokenJwt.jti,
        nameid: decodedTokenJwt.nameid,
        name: decodedTokenJwt.given_name.toLowerCase(),
        surname: decodedTokenJwt.family_name.toLowerCase(),
        customerName: decodedTokenJwt.customer_name.toLowerCase(),
        privileges: decodedTokenJwt.IBUHOO_PRIVILEGE,
        //IBUHOO_PRIVILEGE: ["SECURITY_USERS_READ", "SECURITY_APPLICATION_DELETE", "SECURITY_USERS_DELETE", "SECURITY_DOCUMENTTYPE_CREATE", "SECURITY_DOCUMENTTYPE_READ", "SECURITY_CUSTOMER_MODIFY", "SECURITY_PRIVILEGE_MODIFY", "SECURITY_CUSTOMER_CREATE", "SECURITY_TIMEUNIT_CREATE", "SECURITY_DOCUMENTTYPE_DELETE", "SECURITY_STATE_DELETE", "SECURITY_ROLE_MODIFY", "SECURITY_TIMEUNIT_READ", "SECURITY_USERS_MODIFY", "SECURITY_USERS_CREATE", "SECURITY_STATE_READ", "SECURITY_PRIVILEGE_CREATE", "SECURITY_APPLICATION_MODIFY", "SECURITY_ROLE_READ", "SECURITY_TIMEUNIT_DELETE", "SECURITY_STATE_CREATE", "SECURITY_ROLE_DELETE", "SECURITY_PRIVILEGE_READ", "SECURITY_APPLICATION_READ", "SECURITY_CUSTOMER_READ", "SECURITY_ROLE_CREATE", "SECURITY_APPLICATION_CREATE", "SECURITY_PRIVILEGE_DELETE", "SECURITY_TIMEUNIT_MODIFY", "SECURITY_STATE_MODIFY", "SECURITY_DOCUMENTTYPE_MODIFY", "SECURITY_CUSTOMER_DELETE"]
        //IBUHOO_ROLE: "ROL1"
      };
      assignAuthToken(token);
      console.log("userTokenData", userTokenData);
      localStorage.setItem("userTokenData", JSON.stringify(userTokenData));
      /*dispatch({
        type: SET_IS_AUTHENTICATED,
        payload: true,
      });*/
    } else {
      console.log("error");
      redirectToLogin();
    }
  } catch (error) {
    const { response } = error;

    console.log(error);
    if (response) {
      console.log(error);
      redirectToLogin();
    }
  }
}
/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || "", "base64").toString("hex")
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);
    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      return paramsObj.token;
    } else {
      console.log("err");
      redirectToLogin();
    }
  } catch (err) {
    console.log(err);
    redirectToLogin();
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\000-\031\200-\377]+/g, "");
    return paramsChar;
  } catch (err) {
    console.log("Error limpiando parametros:", err);
  }
}

/**
 * validar vigencia del token
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const isValidToken = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    return tokenValidity(decodedTokenJwt);
  } catch (err) {
    console.log("Error validando Token:", err);
    return false;
  }
};

/**
 * valida que la vigencia del token se mayor o igual a 20 segundos
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const tokenValidationTime = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedTokenJwt.exp).utc();
    return (moment(dateExpiresToken).diff(currentDate, "seconds") < TIME_REFRESH_TOKEN);
  } catch (err) {
    console.log("Error validando Token:", err);
    return false;
  }
};


/**
 * Invoca el api de refreshToken y retorna un nuevo token
 * @param {refreshData} refreshData 
 */
export const refreshToken = (refreshData) => async () => {
  try {
    await axios.post(API_AUTHENTICATION + "/api/Login/RefreshToken", refreshData).then((response) => {
        console.log("Response api ", response);
        assignAuthToken(response.data.result.token);      
    });
  } catch (err) {
    console.log("Error refrescando Token:", err);
  }
};

/**
 * validar vigencia del token
 *
 * @method tokenValidity
 * @param {querytokenString} token
 */
export const tokenValidity = (decodedToken) => {
  try {
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();
    return moment(dateExpiresToken).isAfter(currentDate);
  } catch (err) {
    console.log("Error validando Token:", err);
    return false;
  }
};

/**
 * Redireccionar a login
 *
 * @method redirectToLogin
 * @param
 */
function redirectToLogin() {
  console.log("redirectToLogin");
  assignAuthToken(false);
  localStorage.removeItem("userTokenData");
  window.location.href = URL_SECURITY;
}
