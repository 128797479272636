import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
/**
 * InputForm constant ( generic HTML input for recursive implementation in forms )
 *
 * @param {*} {...props}
 * @returns HTML object
 */
const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: "500",
    color: "#B2B2B2",
    fontSize: "18px",
    "&:hover": {
      color: "#4F4F4F",
      fontWeight: "400",
      border: "none",
    },
    padding: "8px 2px",
  },
  root: {
    paddingLeft: "0px",
    boxShadow: "0px 4px 3px #00000033",
    borderRadius: "11px",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));

const InputForm = ({ ...props }) => {
  const {
    icon,
    placeholder,
    id,
    onChange,
    value,
    label,
    disabled,
    validator,
    validateOptions,
    onBlur,
    error,
  } = props;
  const [visibility, setVisibility] = useState(true);
  const [type, setType] = useState(props.type);
  const isPassword = props.type === "password";
  const showPassword = () => {
    visibility ? setType("text") : setType("password");
    visibility ? setVisibility(false) : setVisibility(true);
  };
  const classes = useStyles();
  if (!!!validator) {
    return <></>;
  }

  return (
    <div>
      <div>
        <TextField
          error={
            error || validator.message("", value, validateOptions)
              ? true
              : false
          }
          id={id}
          type={type}
          label={label}
          defaultValue={value}
          helperText={validator.message("", value, validateOptions)}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            classes,
            startAdornment: !!icon ? (
              <InputAdornment position="start">
                {icon === "Person" ? <PersonIcon color="secondary" /> : null}
                {icon === "Lock" ? <LockIcon color="secondary" /> : null}
                {icon === "Email" ? <EmailIcon color="secondary" /> : null}
              </InputAdornment>
            ) : (
              ""
            ),
            endAdornment: isPassword ? (
              <InputAdornment position="end">
                <IconButton onClick={() => showPassword()}>
                  {visibility ? (
                    <VisibilityIcon fontSize="small"></VisibilityIcon>
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            ),
          }}
        />
      </div>
    </div>
  );
};

function PersonIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 35 34"
      style={{ width: "1.5em", height: "2.5em" }}
    >
      <g transform="translate(-825 -489)">
        <path
          d="M9.82,0H35.57a0,0,0,0,1,0,0V33.217a0,0,0,0,1,0,0H9.82A9.82,9.82,0,0,1,0,23.4V9.82A9.82,9.82,0,0,1,9.82,0Z"
          transform="translate(825 491.606)"
        />
        <circle
          style={{ fill: "#fff" }}
          cx="3.515"
          cy="3.515"
          r="3.515"
          transform="translate(839.271 501.333)"
        />
        <path
          style={{ fill: "#fff" }}
          d="M6.243,0h0a6.243,6.243,0,0,1,6.243,6.243v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A6.243,6.243,0,0,1,6.243,0Z"
          transform="translate(836.542 508.852)"
        />
      </g>
    </SvgIcon>
  );
}

function LockIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 35 34"
      style={{ width: "1.5em", height: "2.5em" }}
    >
      <g transform="translate(-825 -543)">
        <path
          d="M9.82,0H35.57V33.217H9.82A9.82,9.82,0,0,1,0,23.4V9.82A9.82,9.82,0,0,1,9.82,0Z"
          transform="translate(825 545.071)"
        />
        <path
          style={{ fill: "#fff" }}
          d="M846.914,560.86h-8.258a1.4,1.4,0,0,0-1.4,1.4v5.635a1.4,1.4,0,0,0,1.4,1.4h8.258a1.4,1.4,0,0,0,1.4-1.4v-5.635A1.4,1.4,0,0,0,846.914,560.86Zm-4.129,6.183a1.062,1.062,0,0,1-.468-2.016v-1.1a.468.468,0,1,1,.936,0v1.1a1.062,1.062,0,0,1-.468,2.016Z"
        />
        <path
          style={{ fill: "#fff" }}
          d="M839.1,558.049v2.327h1.451v-2.327a2.23,2.23,0,1,1,4.46,0v2.327h1.451v-2.327a3.681,3.681,0,1,0-7.362,0Z"
        />
      </g>
    </SvgIcon>
  );
}

function EmailIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 35 34"
      style={{ width: "1.5em", height: "2.5em" }}
    >
      <g transform="translate(-773.065 -582)">
        <path
          d="M9.82,0H35.57a0,0,0,0,1,0,0V33.217a0,0,0,0,1,0,0H9.82A9.82,9.82,0,0,1,0,23.4V9.82A9.82,9.82,0,0,1,9.82,0Z"
          transform="translate(773.065 584.167)"
        />
        <g
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fill: "#fff",
            stroke: "none",
          }}
        >
          <path d="M 798.3193359375 605.850341796875 L 794.0166625976563 605.850341796875 L 787.6825561523438 605.850341796875 L 783.3796997070313 605.850341796875 L 786.818115234375 601.5791015625 L 790.5700073242188 604.9653930664063 L 790.8492431640625 605.2174072265625 L 791.1286010742188 604.9656372070313 L 794.8832397460938 601.5819091796875 L 798.3193359375 605.850341796875 Z" />
          <path
            style={{ fill: "#fff", stroke: "none" }}
            d="M 797.4481811523438 605.4332885742188 L 794.8348388671875 602.1869506835938 L 791.4078369140625 605.2754516601563 C 791.3306884765625 605.344970703125 791.2437744140625 605.3975830078125 791.15185546875 605.4332885742188 L 794.0166625976563 605.4332885742188 L 797.4481811523438 605.4332885742188 M 790.5469970703125 605.4332885742188 C 790.4548950195313 605.3975219726563 790.3678588867188 605.3447265625 790.2905883789063 605.2750244140625 L 786.8662719726563 602.1843872070313 L 784.2508544921875 605.4332885742188 L 787.6825561523438 605.4332885742188 L 790.5469970703125 605.4332885742188 M 799.1904296875 606.2673950195313 L 794.0166625976563 606.2673950195313 L 787.6825561523438 606.2673950195313 L 782.5086059570313 606.2673950195313 L 786.7699584960938 600.973876953125 L 790.8494262695313 604.6558227539063 L 794.9315795898438 600.9769287109375 L 799.1904296875 606.2673950195313 Z"
          />
        </g>
        <path
          style={{ fill: "#fff", stroke: "none" }}
          d="M782.505,595.55l8.344,7.627,8.344-7.627Z"
        />
        <g
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fill: "#fff",
            stroke: "none",
          }}
        >
          <path d="M 782.9256591796875 603.544189453125 L 782.9256591796875 597.9871215820313 L 785.502685546875 600.3427734375 L 782.9256591796875 603.544189453125 Z" />
          <path
            style={{ fill: "#fff", stroke: "none" }}
            d="M 783.3427124023438 598.933349609375 L 783.3427124023438 602.3609619140625 L 784.9321899414063 600.3863525390625 L 783.3427124023438 598.933349609375 M 782.5086059570313 597.0408935546875 L 786.0731201171875 600.2991943359375 L 782.5086059570313 604.7273559570313 L 782.5086059570313 597.0408935546875 Z"
          />
        </g>
        <g
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fill: "#fff",
            stroke: "none",
          }}
        >
          <path d="M 798.7733764648438 603.544189453125 L 796.1963500976563 600.3427734375 L 798.7733764648438 597.9871215820313 L 798.7733764648438 603.544189453125 Z" />
          <path
            style={{ fill: "#fff", stroke: "none" }}
            d="M 798.3563232421875 598.933349609375 L 796.766845703125 600.3863525390625 L 798.3563232421875 602.3609619140625 L 798.3563232421875 598.933349609375 M 799.1904296875 597.0408935546875 L 799.1904296875 604.7273559570313 L 795.6259155273438 600.2991943359375 L 799.1904296875 597.0408935546875 Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

InputForm.propTypes = {
  labelProps: PropTypes.object,
  id: PropTypes.string,
};
export default InputForm;
