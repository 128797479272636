import { combineReducers } from "redux/es/redux";
import authReducer from "./auth.reducer";
import errorReducer from "./error.reducer";
import loadingReducer from "./loading.reducer";
import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";

export default combineReducers({
  authReducer,
  errorReducer,
  loadingReducer,
  menuReducer,
  userReducer,
});
